import React from 'react';
import { Link } from 'react-router-dom';
const Sidebar= ()=>{
    return(
        <>

{/* Navbar */}
<div className="app-navbar">
  <div className="container">
    <div className="navbar-content ">
      <div className="navbar-content-item">
        <Link to={'/'} className="active">
          <i className="flaticon-house" />
          Home
        </Link>
      </div>
      <div className="navbar-content-item">
        <Link to={'/login'}>
          <i className="flaticon-invoice" />
          Reports
        </Link>
      </div>
      <div className="navbar-content-item">
        <Link to={'/recharge'}>
          <i className="flaticon-menu-1" />
          Referal
        </Link>
      </div>
      <div className="navbar-content-item">
        <Link to={'/setting'}>
          <i className="flaticon-settings" />
          Setting
        </Link>
      </div>
    </div>
  </div>
</div>
{/* Navbar */}

<div className="modal fade" id="sidebarDrawer" tabIndex={-1} aria-labelledby="sidebarDrawer" aria-hidden="true">
  <div className="modal-dialog side-modal-dialog">
    <div className="modal-content">
      <div className="modal-header sidebar-modal-header">
        <div className="sidebar-profile-info">
          <div className="sidebar-profile-thumb">
            <img src="assets/images/profile.jpg" alt="profile" />
          </div>
          <div className="sidebar-profile-text">
            <h3>Brenda Davis</h3>
            <p><a href="tel:1545-8880">1545 8880</a></p>
          </div>
        </div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
      </div>
      <div className="sidebar-profile-wallet">
        <div className="add-card-info">
          <p>Your Wallet</p>
          <h3>$1,450.50</h3>
        </div>
      </div>
      <div className="modal-body">
        <div className="sidebar-nav">
          <div className="sidebar-nav-item">
            <h3>Oban Menu</h3>
            <ul className="sidebar-nav-list">
              <li><Link to={'/'} className="active">
          <i className="flaticon-house" />
          Home
        </Link></li>
              <li><Link to={'/login'}>
          <i className="flaticon-invoice" />
          Login
        </Link></li>
              <li><Link to={'/recharge'}>
          <i className="flaticon-menu-1" />
          Recharge
        </Link></li>
              <li><Link to={'/setting'}>
          <i className="flaticon-settings" />
          Setting
        </Link></li>
              <li><Link to={'/profile'}>
          <i className="flaticon-settings" />
          Profile
        </Link></li>
              <li><Link to={'/transaction'}>
          <i className="flaticon-settings" />
          Transaction
        </Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



        </>
    );
}
export default Sidebar;