import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
const Plans = () => {
    return (
        <>
            <Header />
            <div className="body-content">
                <div className="container" style={{ textAlign: 'left' }}>
                    {/* Page-header */}
                    <div className="page-header">
                        <div className="page-header-title page-header-item">
                            <h3>Recharge and Bill Payments</h3>
                        </div>
                    </div>
                    {/* Page-header */}
                    {/* Authentication-section */}
                    <div className="authentication-form pb-15">
                        <div class="mt-4 p-5 bg-secondary text-light rounded">
                            <div className="row">
                                <div className="col-sm">
                                    <input type="text" className="form-control" value={"Vi Prepaid"} data-bs-toggle="modal" data-bs-target="#operatormodal"/>
                                </div>
                                <div className="col-sm"></div>
                                <div className="col-sm">
                                <input type="text" className="form-control" value={"Uttar Pradesh East"} data-bs-toggle="modal" data-bs-target="#circlemodal"/>
                                </div>
                            </div>
                            
                            <h4 className="text-light mt-2">Bsnl, UP East</h4>

                        </div>
                        <div className="row mt-5">
                            <div className="col-sm">
                            {/* Tab-selector */}
<div className="tab-selector">
  {/* Tab-selector-list */}
  <ul className="tab-selector-list">
    <li className="active" data-tab-list={1}>
      <button>Special Offers</button>
    </li>
    <li data-tab-list={2}>
      <button>Top Up</button>
    </li>
    <li data-tab-list={3}>
      <button>Full Talktime</button>
    </li>
  </ul>
</div>

                    {/* Tab-selector-list */}
{/* Tab-selector-details */}
<div className="tab-selector-details">
  <div className="tab-selector-details-item active" data-tab-details={1}>
    <div className="row gx-3">
      
    <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div className="tab-selector-details-item" data-tab-details={2}>
    <div className="row gx-3">
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div className="tab-selector-details-item" data-tab-details={3}>
    <div className="row gx-3">
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
      <div className="col-4 pb-15 ">
        <div className="monthly-bill-card monthly-bill-card-green">
          <div className="row">
            <div className="col-sm">
            <div className="monthly-bill-body text-start">
            <h3><b>Talktime -</b></h3>
            <h3><b>Validity -</b> 28 days</h3>
            <h3><b>Data -</b> Unlimited</h3>
          </div>
            </div>
            <div className="col-sm">
            <div className="monthly-bill-footer monthly-bill-action">
            <b className="btn main-btn btn-md">INR 18</b>
          </div>
            </div>
          </div>
          
          <div className="monthly-bill-body">
          <p>Unlimited voice + Video Calls + Unlimited Data(Speed Restricted) 80 GB/Day</p>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
{/* Tab-selector-details */}
{/* Tab-selector */}

                            </div>
                        </div>
                    </div>
                    {/* Authentication-section */}
                </div>
            </div>
            <Sidebar />
            <div className="modal fade" id="operatormodal" tabIndex={-1} aria-labelledby="addressModal" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="container">
        <div className="modal-header">
          <div className="modal-header-title">
            <h5 className="modal-title">Operator</h5>
          </div>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body text-right">
          <form>
            <div className="form-group mb-15">
              <label className="form-label">Select Your Operator</label>
              <select type="text" className="form-control">
                <option>Airtel Prepaid</option>
                <option>Jio Prepaid</option>
                <option>Vi Prepaid</option>
                <option>BSNL Prepaid</option>
                </select>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="modal fade" id="circlemodal" tabIndex={-1} aria-labelledby="addressModal" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="container">
        <div className="modal-header">
          <div className="modal-header-title">
            <h5 className="modal-title">Circle</h5>
          </div>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body text-right">
          <form>
            <div className="form-group mb-15">
              <label className="form-label">Select Your Circle</label>
              <select type="text" className="form-control">
                <option>Uttar Pradesh</option>
                <option>Delhi</option>
                <option>Mumbai</option>
                <option>Jharkhand</option>
                <option>Hariyana</option>
                </select>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<Footer/>
        </>
    );
}

export default Plans;