import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Setting from './pages/Setting';
import Login from './pages/Login';
import Register from './pages/Register';
import Forgotpassword from './pages/Forgot-password';
import Recharge from './pages/Recharge';
import Transaction from './pages/Transaction';
import Transdetail from './pages/Transdetail';
import Profile from './pages/Profile';
import Pay from './pages/Pay';
import Plans from './pages/Plans';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="setting" element={<Setting />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/forgot-password" element={<Forgotpassword />}></Route>
        <Route path="/recharge" element={<Recharge />}></Route>
        <Route path="/transaction" element={<Transaction />}></Route>
        <Route path="/transdetail" element={<Transdetail />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/pay" element={<Pay />}></Route>
        <Route path="/plans" element={<Plans />}></Route>
      </Routes>
    
    </BrowserRouter>
    </div>
  );
}

export default App;
