import React from 'react';
import { Link } from 'react-router-dom';
const Forgotpassword= ()=>{
    return(
        <>
       {/* Header-bg */}
<div className="header-bg header-bg-1" />
{/* Header-bg */}

        <div className="fixed-top">
            <div className="appbar-area sticky-black">
                <div className="container">
                <div className="appbar-container">
                    <div className="appbar-item appbar-actions">
                    <div className="appbar-action-item">
                        <a href="#" className="back-page"><i className="flaticon-left-arrow" /></a>
                    </div>
                    </div>
                    <div className="appbar-item appbar-page-title mx-auto">
                    <h3>Sign In</h3>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div className="body-content">
  <div className="container">
    {/* Page-header */}
    <div className="page-header">
      <div className="page-header-title page-header-item">
        <h3>Sign In To Oban</h3>
      </div>
    </div>
    {/* Page-header */}
    {/* Authentication-section */}
    <div className="authentication-form pb-15">
      <form>
        <div className="form-group pb-15">
          <label>Your Email Address</label>
          <div className="input-group">
            <input type="text" name="name" className="form-control" required placeholder="Your Email" />
            <span className="input-group-text"><i className="flaticon-user-picture" /></span>
          </div>
        </div>
        <button type="submit" class="btn main-btn main-btn-lg full-width">Reset Password</button>
      </form>
    </div>
    {/* Authentication-section */}
  </div>
</div>

        </>
    );
}
export default Forgotpassword;