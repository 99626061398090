import React from 'react';

const Profile= ()=>{
    return(
        <>
<div>
  <footer className="footer-bg">
    <div className="container">
      <p>Copyright © 2021 Oban. Designed &amp; Developed By <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
    </div>
  </footer>
  <div class="scroll-top" id="scrolltop">
            <div class="scroll-top-inner">
                <i class="icofont-long-arrow-up"></i>
            </div>
        </div>
  {/* Footer */}
</div>

        </>
    );
}
export default Profile;