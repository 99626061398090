import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
const Pay = () =>{
    return(
        <>
        <Header/>
        <div className="body-content">
                <div className="container" style={{textAlign:'left'}}>
                    {/* Page-header */}
                    <div className="page-header">
                        <div className="page-header-title page-header-item">
                            <h3>Choose you Plan</h3>
                        </div>
                    </div>
                    {/* Page-header */}
                    {/* Authentication-section */}
                    <div className="authentication-form pb-15">
                    <div className="feature-section mb-15">
      <div className="row gx-3">
        <div className="col col-sm-12 pb-15">
          <div className="feature-card feature-card-red">
            <div className="feature-card-thumb">
              <i className="flaticon-income" />
            </div>
            <div className="feature-card-details">
                <h3>Rs 249 (Plan)</h3>
              <p>Data : 2 GB </p>
              <p>Validity : Base Plan</p>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
                        <form>
                       
                            <button className="btn main-btn main-btn-lg full-width mb-10">Proceed to pay</button>
                        </form>
                    </div>
                    {/* Authentication-section */}
                </div>
            </div>
        <Footer/>
        <Sidebar/>
        </>
    );
}

export default Pay;