import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
const Setting= ()=>{
    return(
        <>
<Header/>
<div className="body-content">
  <div className="container">
    {/* Setting */}
    <div className="setting-section pb-15">
      <div className="user-setting-thumb">
        <div className="user-setting-thumb-up">
          <img src="assets/images/profile.jpg" alt="profile" />
        </div>
        <label htmlFor="upThumb">
          <i className="flaticon-camera" />
        </label>
        <input type="file" id="upThumb" className="d-none" />
      </div>
      {/* Setting-list */}
      <div className="setting-list">
        <ul>
          <li>
            <a href="#" data-bs-toggle="modal" data-bs-target="#userName">Change Username</a>
          </li>
          <li>
            <a href="#" data-bs-toggle="modal" data-bs-target="#emailModal">Update E-mail</a>
          </li>
          <li>
            <a href="#" data-bs-toggle="modal" data-bs-target="#addressModal">Address</a>
          </li>
          <li>
            <a href="#" data-bs-toggle="modal" data-bs-target="#birthdayModal">Birthday</a>
          </li>
          <li>
            <a href="#" data-bs-toggle="modal" data-bs-target="#languageModal">Language</a>
          </li>
          <li>
            <a href="#" data-bs-toggle="modal" data-bs-target="#passwordModal">Update Password</a>
          </li>
          <li className="setting-toggle-btn">
            <a href="#">
              Notifications Sound
            </a>
            <button />
          </li>
          <li>
            <a href="#">Logout</a>
          </li>
        </ul>
      </div>
      {/* Setting-list */}
    </div>
    {/* Setting */}
  </div>
</div>

<Footer/>
<Sidebar/>

        </>
    );
}
export default Setting;