import React from 'react';
import { Link } from 'react-router-dom';
const Header= ()=>{
    return(
        <>
        <div>
  {/* Preloader */}
  <div className="preloader">
    <div className="preloader-wrapper">
      <div className="preloader-content">
        <img src="assets/images/preloader-logo.png" alt="logo" />
        <h3>Online Banking</h3>
      </div>
    </div>
  </div>
  {/* Preloader */}
  {/* Header-bg */}
  <div className="header-bg header-bg-1" />
  {/* Header-bg */}
</div>

<div className="fixed-top">
  <div className="appbar-area sticky-black">
    <div className="container">
      <div className="appbar-container">
        <div className="appbar-item appbar-actions">
          <div className="appbar-action-item">
            <a href="#" className="appbar-action-bar" data-bs-toggle="modal" data-bs-target="#sidebarDrawer"><i className="flaticon-menu" /></a>
          </div>
        </div>
        <div className="appbar-item appbar-brand me-auto">
        <Link to={'/setting'}>
            <img src="assets/images/logo.png" alt="logo" className="main-logo" />
            <img src="assets/images/logo-hover.png" alt="logo" className="hover-logo" />
          </Link>
        </div>
        <div className="appbar-item appbar-options">
          {/* <div className="appbar-option-item appbar-option-notification">
          <Link to={'/noti'}><i className="flaticon-bell" /></Link>
            <span className="option-badge">5</span>
          </div> */}
          <div className="appbar-option-item appbar-option-profile">
            <Link to={'/setting'}><img src="assets/images/profile.jpg" alt="profile" /></Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    );
}
export default Header;