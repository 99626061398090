import React from 'react';

const Footer= ()=>{
    return(
        <>
<div>
  <footer className="footer-bg">
    <div className="container">
      <p>Recharge App</p>
    </div>
  </footer>
  <div class="scroll-top" id="scrolltop">
            <div class="scroll-top-inner">
                <i class="icofont-long-arrow-up"></i>
            </div>
        </div>
  {/* Footer */}
</div>

        </>
    );
}
export default Footer;