import React, { useState }  from 'react';
import { Link } from 'react-router-dom';
const Register= ()=>{

    return(
        <>
       {/* Header-bg */}
<div className="header-bg header-bg-1" />
{/* Header-bg */}

        <div className="fixed-top">
            <div className="appbar-area sticky-black">
                <div className="container">
                <div className="appbar-container">
                    <div className="appbar-item appbar-actions">
                    <div className="appbar-action-item">
                        <a href="#" className="back-page"><i className="flaticon-left-arrow" /></a>
                    </div>
                    </div>
                    <div className="appbar-item appbar-page-title mx-auto">
                    <h3>Sign In</h3>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div className="body-content">
  <div className="container">
    {/* Page-header */}
    <div className="page-header">
      <div className="page-header-title page-header-item">
        <h3>Sign In To Oban</h3>
      </div>
    </div>
    {/* Page-header */}
    {/* Authentication-section */}
    <div className="authentication-form pb-15">
      <form>
        <div className="form-group pb-15">
          <label>Name</label>
          <div className="input-group">
            <input type="text" name="name" className="form-control" required placeholder="Alicia Williams" />
            <span className="input-group-text"><i className="flaticon-user-picture" /></span>
          </div>
        </div>
        <div className="form-group pb-15">
          <label>Email</label>
          <div className="input-group">
            <input type="text" name="name" className="form-control" required placeholder="Alicia Williams" />
            <span className="input-group-text"><i className="flaticon-user-picture" /></span>
          </div>
        </div>
        <div className="form-group pb-15">
          <label>Mobile Number</label>
          <div className="input-group">
            <input type="text" name="name" className="form-control" required placeholder="Alicia Williams" />
            <span className="input-group-text"><i className="flaticon-user-picture" /></span>
          </div>
        </div>
        <div className="form-group pb-15">
          <label>Referal Code (optional)</label>
          <div className="input-group">
            <input type="number" name="password" className="form-control password" required placeholder="OTP" />
            <span className="input-group-text reveal">
              <i className="flaticon-invisible pass-close" />
              <i className="flaticon-visibility pass-view" />
            </span>
          </div>
        </div>
        <div className="authentication-account-access pb-15">
          <div className="authentication-account-access-item">
            <div className="authentication-link">
              Already have Account ?<Link to={'/login'}> Login </Link>
            </div>
          </div>
        </div>
        <button className="btn main-btn main-btn-lg full-width mb-10">Sign In</button>
        <a href="#" className="btn main-btn main-btn-lg main-btn-red full-width mb-10"><i className="flaticon-google" /> Sign In With Google</a>
      </form>
      <div className="form-desc">Don’t have an account? <Link to={'/register'}>Sign Up Now!</Link></div>
    </div>
    {/* Authentication-section */}
  </div>
</div>

        </>
    );
}
export default Register;