import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
const Transaction = () =>{
    return(
        <>
        <Header/>
        <div className="body-content body-content-lg"> {/* "body-content-lg" add this class if any cards inside this div has "section-to-header" class */}
  <div className="container">
    
    {/* Transaction-section */}
    <div className="transaction-section pb-15">
      <div className="section-header">
        <h2>Transactions</h2>
        <div className="view-all">
          <a href="transactions.html">View All</a>
        </div>
      </div>
      <div className="transaction-card mb-15">
        <Link to={'transdetail'}>
          <div className="transaction-card-info">
            <div className="transaction-info-thumb">
              <img src="assets/images/user-2.jpg" alt="user" />
            </div>
            <div className="transaction-info-text">
              <h3>Brenda Davis</h3>
              <p>Transfer</p>
            </div>
          </div>
          <div className="transaction-card-det negative-number">
            -$185.00
          </div>
        </Link>
      </div>
      <div className="transaction-card mb-15">
      <Link to={'transdetail'}>
          <div className="transaction-card-info">
            <div className="transaction-info-thumb">
              <img src="assets/images/user-cm-logo-1.png" alt="user" />
            </div>
            <div className="transaction-info-text">
              <h3>Appstore Purchase</h3>
              <p>App Purchase</p>
            </div>
          </div>
          <div className="transaction-card-det">
            -$159.99
          </div>
        </Link>
      </div>
      <div className="transaction-card mb-15">
      <Link to={'transdetail'}>
          <div className="transaction-card-info">
            <div className="transaction-info-thumb">
              <img src="assets/images/user-1.jpg" alt="user" />
            </div>
            <div className="transaction-info-text">
              <h3>Martin Neely</h3>
              <p>Transfer</p>
            </div>
          </div>
          <div className="transaction-card-det">
            +$170.00
          </div>
        </Link>
      </div>
      <div className="transaction-card mb-15">
      <Link to={'transdetail'}>
          <div className="transaction-card-info">
            <div className="transaction-info-thumb">
              <img src="assets/images/user-3.jpg" alt="user" />
            </div>
            <div className="transaction-info-text">
              <h3>Mary McMillian</h3>
              <p>Transfer</p>
            </div>
          </div>
          <div className="transaction-card-det">
            +$2573.00
          </div>
        </Link>
      </div>
    </div>
    {/* Transaction-section */}
    
  </div>
</div>
        <Footer/>
        <Sidebar/>
        </>
    );
}

export default Transaction;