import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
const Recharge = () => {
    return (
        <>
            <Header />
            <div className="body-content">
                <div className="container" style={{ textAlign: 'left' }}>
                    {/* Page-header */}
                    <div className="page-header">
                        <div className="page-header-title page-header-item">
                            <h3>Recharge and Bill Payments</h3>
                        </div>
                    </div>
                    {/* Page-header */}
                    {/* Authentication-section */}
                    <div className="authentication-form pb-15">
                        <div className="page-headers-title page-header-item">
                            <h3>Popular Services</h3>
                        </div>
                        <div className="row align-items-center mt-5">
                            <div className="col-sm justify-content-center">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#userName">
                                    <img src="assets/images/img/user-interface.png" className="img-fluid recharge-icon" alt="recharge"></img>
                                    <h4 className="mt-4" style={{ marginLeft: '43px' }}>Mobile</h4>
                                </a>
                            </div>
                            <div className="col-sm justify-content-center">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#dishtv">
                                    <img src="assets/images/img/satellite-dish.png" className="img-fluid recharge-icon" alt="recharge"></img>
                                    <h4 className="mt-4" style={{ marginLeft: '43px' }}>DTH</h4>
                                </a>
                            </div>
                            <div className="col-sm justify-content-center">


                            </div>
                        </div>
                    </div>
                    {/* Authentication-section */}
                </div>
            </div>
            <Sidebar />
            {/* Username-modal */}
            <div className="modal fade" id="userName" tabIndex={-1} aria-labelledby="userName" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content text-start">
                        <div className="container">
                            <div className="modal-header">
                                <div className="modal-header-title">
                                    <h5 className="modal-title">Your Mobile Number</h5>
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group mb-15">
                                        <label className="form-label">Mobile Number</label>
                                        <input type="text" className="form-control" placeholder="0000000000" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="dishtv" tabIndex={-1} aria-labelledby="dishtv" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content text-start">
                        <div className="container">
                            <div className="modal-header">
                                <div className="modal-header-title">
                                    <h5 className="modal-title">All Billers</h5>
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div class="saving-goals-section">
                                    <div class="progress-card progress-card-red mb-15">
                                        <div class="progress-card-info">
                                            <div class="circular-progress" data-note="50.85">
                                                <svg width="55" height="55" class="circle-svg">
                                                    <circle cx="28" cy="27" r="25" class="circle-progress circle-progress-path"></circle>
                                                    <circle cx="28" cy="27" r="25" class="circle-progress circle-progress-fill"></circle>
                                                </svg>
                                                <div class="percent">
                                                    <img src="assets/images/img/satellite-dish.png" className="img-fluid" alt="recharge"></img>
                                                </div>
                                            </div>
                                            <div class="progress-info-text">
                                                <h3>Airtel TV</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="saving-goals-section">
                                    <div class="progress-card progress-card-red mb-15">
                                        <div class="progress-card-info">
                                            <div class="circular-progress" data-note="50.85">
                                                <svg width="55" height="55" class="circle-svg">
                                                    <circle cx="28" cy="27" r="25" class="circle-progress circle-progress-path"></circle>
                                                    <circle cx="28" cy="27" r="25" class="circle-progress circle-progress-fill"></circle>
                                                </svg>
                                                <div class="percent">
                                                    <img src="assets/images/img/satellite-dish.png" className="img-fluid" alt="recharge"></img>
                                                </div>
                                            </div>
                                            <div class="progress-info-text">
                                                <h3>Dish TV</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="saving-goals-section">
                                    <div class="progress-card progress-card-red mb-15">
                                        <div class="progress-card-info">
                                            <div class="circular-progress" data-note="50.85">
                                                <svg width="55" height="55" class="circle-svg">
                                                    <circle cx="28" cy="27" r="25" class="circle-progress circle-progress-path"></circle>
                                                    <circle cx="28" cy="27" r="25" class="circle-progress circle-progress-fill"></circle>
                                                </svg>
                                                <div class="percent">
                                                    <img src="assets/images/img/satellite-dish.png" className="img-fluid" alt="recharge"></img>
                                                </div>
                                            </div>
                                            <div class="progress-info-text">
                                                <h3>Tata Sky TV</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Username-modal */}

        </>
    );
}

export default Recharge;