import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
const Home= ()=>{
    return(
        <>
            <Header/>
            <div className="body-content body-content-lg"> {/* "body-content-lg" add this class if any cards inside this div has "section-to-header" class */}
  <div className="container">
    {/* Add-card */}
    <div className="add-card section-to-header mb-30">
      <div className="add-card-inner">
        <div className="add-card-item add-card-info">
          <p>Your Wallet</p>
          <h3>$1,450.50</h3>
        </div>
        <div className="add-card-item add-card-info">
         
          <h3>Mobile Recharge DTH</h3>
        </div>
      </div>
      
    </div>
    {/* Add-card */}
    {/* Option-section */}
    <div className="option-section mb-15">
      <div className="row gx-3">
        <div className="col pb-15">
          <div className="option-card option-card-violet">
            <a href="#" data-bs-toggle="modal" data-bs-target="#withdraw">
              <div className="option-card-icon">
                <i className="flaticon-down-arrow" />
              </div>
              <p>Withdraw</p>
            </a>
          </div>
        </div>
        <div className="col pb-15">
          <div className="option-card option-card-yellow">
            <a href="#" data-bs-toggle="modal" data-bs-target="#sendMoney">
              <div className="option-card-icon">
                <i className="flaticon-right-arrow" />
              </div>
              <p>Send</p>
            </a>
          </div>
        </div>
        <div className="col pb-15">
          <div className="option-card option-card-blue">
            <a href="my-cards.html">
              <div className="option-card-icon">
                <i className="flaticon-credit-card" />
              </div>
              <p>Cards</p>
            </a>
          </div>
        </div>
        <div className="col pb-15">
          <div className="option-card option-card-red">
            <a href="#" data-bs-toggle="modal" data-bs-target="#exchange">
              <div className="option-card-icon">
                <i className="flaticon-exchange-arrows" />
              </div>
              <p>Exchange</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* Option-section */}
    {/* Feature-section */}
    <div className="feature-section mb-15">
      <div className="row gx-3">
        <div className="col col-sm-6 pb-15">
          <div className="feature-card feature-card-red">
            <div className="feature-card-thumb">
              <i className="flaticon-income" />
            </div>
            <div className="feature-card-details">
              <p>Income</p>
              <h3>$485.50</h3>
            </div>
          </div>
        </div>
        <div className="col col-sm-6 pb-15">
          <div className="feature-card feature-card-blue">
            <div className="feature-card-thumb">
              <i className="flaticon-expenses" />
            </div>
            <div className="feature-card-details">
              <p>Expenses</p>
              <h3>$95.50</h3>
            </div>
          </div>
        </div>
        <div className="col col-sm-6 pb-15">
          <div className="feature-card feature-card-violet">
            <div className="feature-card-thumb">
              <i className="flaticon-invoice" />
            </div>
            <div className="feature-card-details">
              <p>Total Bills</p>
              <h3>$75.00</h3>
            </div>
          </div>
        </div>
        <div className="col col-sm-6 pb-15">
          <div className="feature-card feature-card-green">
            <div className="feature-card-thumb">
              <i className="flaticon-savings" />
            </div>
            <div className="feature-card-details">
              <p>Savings</p>
              <h3>$285.00</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Feature-section */}
    {/* Transaction-section */}
    <div className="transaction-section pb-15">
      <div className="section-header">
        <h2>Transactions</h2>
        <div className="view-all">
          <a href="transactions.html">View All</a>
        </div>
      </div>
      <div className="transaction-card mb-15">
        <a href="transaction-details.html">
          <div className="transaction-card-info">
            <div className="transaction-info-thumb">
              <img src="assets/images/user-2.jpg" alt="user" />
            </div>
            <div className="transaction-info-text">
              <h3>Brenda Davis</h3>
              <p>Transfer</p>
            </div>
          </div>
          <div className="transaction-card-det negative-number">
            -$185.00
          </div>
        </a>
      </div>
      <div className="transaction-card mb-15">
        <a href="transaction-details.html">
          <div className="transaction-card-info">
            <div className="transaction-info-thumb">
              <img src="assets/images/user-cm-logo-1.png" alt="user" />
            </div>
            <div className="transaction-info-text">
              <h3>Appstore Purchase</h3>
              <p>App Purchase</p>
            </div>
          </div>
          <div className="transaction-card-det">
            -$159.99
          </div>
        </a>
      </div>
      <div className="transaction-card mb-15">
        <a href="transaction-details.html">
          <div className="transaction-card-info">
            <div className="transaction-info-thumb">
              <img src="assets/images/user-1.jpg" alt="user" />
            </div>
            <div className="transaction-info-text">
              <h3>Martin Neely</h3>
              <p>Transfer</p>
            </div>
          </div>
          <div className="transaction-card-det">
            +$170.00
          </div>
        </a>
      </div>
      <div className="transaction-card mb-15">
        <a href="transaction-details.html">
          <div className="transaction-card-info">
            <div className="transaction-info-thumb">
              <img src="assets/images/user-3.jpg" alt="user" />
            </div>
            <div className="transaction-info-text">
              <h3>Mary McMillian</h3>
              <p>Transfer</p>
            </div>
          </div>
          <div className="transaction-card-det">
            +$2573.00
          </div>
        </a>
      </div>
    </div>
    {/* Transaction-section */}
    {/* Monthly-bill-section */}
    <div className="monthly-bill-section pb-15">
      <div className="section-header">
        <h2>Monthly Bills</h2>
        <div className="view-all">
          <a href="monthly-bills.html">View All</a>
        </div>
      </div>
      <div className="row gx-3">
        <div className="col-6 pb-15">
          <div className="monthly-bill-card monthly-bill-card-green">
            <div className="monthly-bill-thumb">
              <img src="assets/images/cm-logo-1.png" alt="logo" />
            </div>
            <div className="monthly-bill-body">
              <h3><a href="#">Envato.com</a></h3>
              <p>Debit Services Subscribtion</p>
            </div>
            <div className="monthly-bill-footer monthly-bill-action">
              <a href="#" className="btn main-btn">Pay Now</a>
              <p className="monthly-bill-price">$99.99</p>
            </div>
          </div>
        </div>
        <div className="col-6 pb-15">
          <div className="monthly-bill-card monthly-bill-card-green">
            <div className="monthly-bill-thumb">
              <img src="assets/images/cm-logo-2.png" alt="logo" />
            </div>
            <div className="monthly-bill-body">
              <h3><a href="#">Oban.com</a></h3>
              <p>Credit Services Subscribtion</p>
            </div>
            <div className="monthly-bill-footer monthly-bill-action">
              <a href="#" className="btn main-btn">Pay Now</a>
              <p className="monthly-bill-price">$75.00</p>
            </div>
          </div>
        </div>
        <div className="col-6 pb-15">
          <div className="monthly-bill-card monthly-bill-card-green">
            <div className="monthly-bill-thumb">
              <img src="assets/images/cm-logo-3.png" alt="logo" />
            </div>
            <div className="monthly-bill-body">
              <h3><a href="#">Nezox.com</a></h3>
              <p>Internet Monthly Subscribtion</p>
            </div>
            <div className="monthly-bill-footer monthly-bill-action">
              <a href="#" className="btn main-btn">Pay Now</a>
              <p className="monthly-bill-price">$50.50</p>
            </div>
          </div>
        </div>
        <div className="col-6 pb-15">
          <div className="monthly-bill-card monthly-bill-card-green">
            <div className="monthly-bill-thumb">
              <img src="assets/images/cm-logo-4.png" alt="logo" />
            </div>
            <div className="monthly-bill-body">
              <h3><a href="#">Depan.com</a></h3>
              <p>Depan Monthly Subscribtion</p>
            </div>
            <div className="monthly-bill-footer monthly-bill-action">
              <a href="#" className="btn main-btn">Pay Now</a>
              <p className="monthly-bill-price">$100.99</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Monthly-bill-section */}
  </div>
</div>

            <Footer/>
            <Sidebar/>
        </>
    );
}
export default Home;