import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";

const Transdetail = () =>{
    return(
        <>
        <Header/>
        <div className="body-content" style={{textAlign:'left'}}>
  <div className="container">
    {/* Page-header */}
    <div className="page-header">
      <div className="page-header-title page-header-item">
        <h3>Confirm Payment</h3>
      </div>
    </div>
    {/* Page-header */}
    {/* Payment-list */}
    <div className="page-header">
      <div className="page-header-title page-header-item">
        
      </div>
    </div>
    <div className="payment-list pb-15">
      <div className="payment-list-details">
        <div className="payment-list-item payment-list-title">
        <h4>873283676</h4>
        <p>Bsnl, UP East</p>
        </div>
        <Link to={'/plans'}>Change Plan</Link>
      </div>
      <div className="payment-list-details">
        <div className="payment-list-item payment-list-title">Plan</div>
        <div className="payment-list-item payment-list-info">Special Offer</div>
      </div>
      <div className="payment-list-details">
        <div className="payment-list-item payment-list-title">Talktime</div>
        <div className="payment-list-item payment-list-info">-</div>
      </div>
      <div className="payment-list-details">
        <div className="payment-list-item payment-list-title">Validity</div>
        <div className="payment-list-item payment-list-info">28 Days</div>
      </div>
      <div className="payment-list-details">
        <div className="payment-list-item payment-list-title">Data</div>
        <div className="payment-list-item payment-list-info">-</div>
      </div>

      <div className="payment-list-details">
      <div class="alert alert-secondary" role="alert">
  Voice Calls (Locals and Nationals) @20 paisa/min for 30 days
</div>
      </div>
     
      <div className="payment-list-details">
        <div className="payment-list-item payment-list-title"><b>Recharge</b></div>
        <div className="payment-list-item payment-list-info"><b>$ 24</b></div>
      </div>

      <div className="payment-list-details">
        <div className="payment-list-item payment-list-title"><b>Total Payment</b></div>
        <div className="payment-list-item payment-list-info"><b>$ 24</b></div>
      </div>

      <div className="payment-list-details">
        <div className="payment-list-item payment-list-title"><b></b></div>
        <div className="payment-list-item payment-list-info"><b><button className="btn btn-primary">Pay Now</button></b></div>
      </div>
    </div>
    {/* Payment-list */}
  </div>
</div>

       
        <Sidebar/>
        </>
    );
}

export default Transdetail;